<script>
import { onMounted, ref } from "vue";
import CardToolbar from "@/common/components/CardToolbar.vue";
import { getPlaylistTracksByCuratorPlaylistID } from "@/api/playlists.api";
import {
  paginate,
  routeToPage,
  sortArray,
  tableCellClassName,
  tableRowClassName,
} from "@/utils/staticHelper";
import moment from "moment";
import { Position } from "@element-plus/icons-vue";
import { TypeEnums } from "@/common/enums/typeEnums";
export default {
  name: "PlaylistTracks",
  computed: {
    TypeEnums() {
      return TypeEnums;
    },
  },
  methods: { routeToPage, tableCellClassName, tableRowClassName },
  components: { Position, CardToolbar },
  props: {
    curatorPlaylistID: {
      required: true,
    },
  },
  setup(props) {
    const loading = ref(false);
    const tableData = ref([]);
    const sortBy = ref(null);
    const sortDir = ref(null);
    const tableDataWithOutPagination = ref([]);
    const localPagination = ref({
      offset: 1,
      limit: 10,
      total: 0,
      sortBy: "position",
      sortOrder: "desc",
    });
    onMounted(() => {
      getPlaylistTracks();
    });
    const getPlaylistTracks = async () => {
      try {
        loading.value = true;
        const { data } = await getPlaylistTracksByCuratorPlaylistID(
          props.curatorPlaylistID
        );
        const tracks = data && data.data ? data.data : [];

        for (const track of tracks) {
          tableDataWithOutPagination.value.push({
            curatorPlaylistID: track.curatorPlaylistID
              ? track.curatorPlaylistID
              : null,
            chartmetricTrackID: track.chartmetricTrackID
              ? track.chartmetricTrackID
              : null,
            trackName: track.trackName ? track.trackName : "N/A",
            spotifyPopularity: track.spotifyPopularity
              ? track.spotifyPopularity
              : 0,
            positionNow: track.position ? track.position : 0,
            addedAt: track.timestp
              ? moment(track.timestp).format("MMM DD, YYYY")
              : "N/A",
            releaseDate:
              track.chartmetricPlaylistTrackRelease &&
              track.chartmetricPlaylistTrackRelease &&
              track.chartmetricPlaylistTrackRelease.release_dates &&
              track.chartmetricPlaylistTrackRelease.release_dates.length > 0
                ? moment(
                    track.chartmetricPlaylistTrackRelease.release_dates[0]
                  ).format("MMM DD, YYYY")
                : "N/A",
            entryPosition:
              track.chartmetricPlaylistTrackRelease &&
              track.chartmetricPlaylistTrackRelease &&
              track.chartmetricPlaylistTrackRelease.position_stats &&
              track.chartmetricPlaylistTrackRelease.position_stats.length > 0
                ? track.chartmetricPlaylistTrackRelease.position_stats[0]
                    .position
                : 0,
            artistName:
              track.chartmetricPlaylistTrackRelease &&
              track.chartmetricPlaylistTrackRelease &&
              track.chartmetricPlaylistTrackRelease.artists &&
              track.chartmetricPlaylistTrackRelease.artists.length > 0
                ? track.chartmetricPlaylistTrackRelease.artists[0].name
                : "N/A",
            chartmetricArtistID:
              track.chartmetricPlaylistTrackRelease &&
              track.chartmetricPlaylistTrackRelease &&
              track.chartmetricPlaylistTrackRelease.artists &&
              track.chartmetricPlaylistTrackRelease.artists.length > 0
                ? track.chartmetricPlaylistTrackRelease.artists[0].id
                : "N/A",
          });
        }
        localPagination.value.total = tableDataWithOutPagination.value.length;
        paginationChanged();
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    const sortResults = ({ prop, order }) => {
      loading.value = true;
      sortBy.value = prop;
      sortDir.value = order;
      if (sortBy.value && sortDir.value) {
        tableDataWithOutPagination.value = sortArray(
          sortBy.value,
          sortDir.value,
          JSON.parse(JSON.stringify(tableDataWithOutPagination.value))
        );
      }
      paginationChanged();
      loading.value = false;
    };

    const paginationChanged = () => {
      tableData.value = paginate(
        tableDataWithOutPagination.value,
        localPagination.value.limit,
        localPagination.value.offset
      );
    };

    return {
      loading,
      tableData,
      sortBy,
      sortDir,
      tableDataWithOutPagination,
      localPagination,
      paginationChanged,
      sortResults,
    };
  },
};
</script>
<template>
  <div class="mt-2 playlist-tracks-section">
    <CardToolbar
      title=""
      :show-toolbar-buttons="false"
      :fontStyle="true"
      :margin="true"
      :padding="true"
      :shadow-class="true"
      :show-header="false"
    >
      <template v-slot:body>
        <el-table
          @sort-change="sortResults"
          v-loading="loading"
          :fit="true"
          size="small"
          :data="tableData"
          tooltip-effect="light"
          stripe
          style="width: 100%"
          header-cell-class-name="table-header-text"
          :row-class-name="tableRowClassName"
          :cell-class-name="tableCellClassName"
        >
          <el-table-column
            prop="trackName"
            label="Track Name"
            sortable="custom"
            fixed
            width="300"
            class-name="cursor-pointer"
          >
            <template #default="scope">
              <p
                @click="
                  routeToPage(
                    `track-detail/${scope.row.chartmetricTrackID}`,
                    TypeEnums.TRACK,
                    scope.row.chartmetricTrackID
                  )
                "
                class="truncate"
                data-tag="p"
                title=""
                data-tooltip="true"
                :data-text="scope.row.trackName"
              >
                {{ scope.row.trackName }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="artistName"
            label="Artist Name"
            sortable="custom"
            width="200"
            class-name="cursor-pointer"
          >
            <template #default="scope">
              <p
                @click="
                  routeToPage(
                    `artist-detail/${scope.row.chartmetricArtistID}`,
                    TypeEnums.ARTIST,
                    scope.row.chartmetricArtistID
                  )
                "
                class="truncate"
                data-tag="p"
                title=""
                data-tooltip="true"
                :data-text="scope.row.artistName"
              >
                {{ scope.row.artistName }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="spotifyPopularity"
            label="Spotify Popularity"
            sortable="custom"
            align="right"
            width="170"
          >
            <template #default="scope">
              {{ scope.row.spotifyPopularity }}
            </template>
          </el-table-column>
          <el-table-column
            prop="addedAt"
            label="Added at"
            sortable="custom"
            align="right"
            width="150"
          >
            <template #default="scope">
              <p
                class="truncate"
                data-tag="p"
                title=""
                data-tooltip="true"
                :data-text="scope.row.addedAt"
              >
                {{ scope.row.addedAt }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="positionNow"
            label="Position Now"
            sortable="custom"
            align="right"
            width="150"
          >
            <template #default="scope">
              {{ scope.row.positionNow }}
            </template>
          </el-table-column>
          <el-table-column
            prop="entryPosition"
            label="Entry Position"
            sortable="custom"
            align="right"
            width="150"
          >
            <template #default="scope">
              {{ scope.row.entryPosition }}
            </template>
          </el-table-column>
          <el-table-column
            prop="releaseDate"
            label="Release Date"
            sortable="custom"
            align="right"
            width="150"
          >
            <template #default="scope">
              {{ scope.row.releaseDate }}
            </template>
          </el-table-column>
          <el-table-column label="" align="right">
            <template #default="scope">
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Track Detail Page"
                placement="left-start"
              >
                <el-icon
                  @click="
                    routeToPage(
                      `track-detail/${scope.row.chartmetricTrackID}`,
                      TypeEnums.TRACK,
                      scope.row.chartmetricTrackID
                    )
                  "
                  class="cursor-pointer"
                  ><Position
                /></el-icon>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt-7 mb-7"
          v-model:currentPage="localPagination.offset"
          v-model:page-size="localPagination.limit"
          :page-sizes="[5, 10, 20, 50, 100]"
          :page-size="localPagination.limit"
          :small="true"
          layout="total, sizes, prev, pager, next"
          :total="localPagination.total"
          @size-change="paginationChanged"
          @current-change="paginationChanged"
        >
        </el-pagination>
      </template>
    </CardToolbar>
  </div>
</template>
