<script>
import { onMounted, ref } from "vue";
import EmptyCardLoading from "@/common/components/EmptyCardLoading.vue";
import CardToolbar from "@/common/components/CardToolbar.vue";
import LineAndAreaChart from "@/common/components/LineAndAreaChart.vue";
import { getPlaylistAudioDnaByCuratorPlaylistID } from "@/api/tracks.api";
import { useRoute } from "vue-router";
import { capitalizeFirstLetter } from "@/utils/staticHelper";
import { ElNotification } from "element-plus";
export default {
  name: "PlaylistAudioFeatures",
  methods: { capitalizeFirstLetter },
  components: { EmptyCardLoading, CardToolbar, LineAndAreaChart },
  setup() {
    const loading = ref(false);
    const route = useRoute();
    const audioFeatures = ref([]);
    const categories = ref([]);
    const colours = ref([
      "#009ef7",
      "#ff3333",
      "#24c278",
      "#6138ad",
      "#fd7e14",
      "#0dcaf0",
      "#dd5599",
      "#3ddb92",
      "#ff33ff",
      "#ffc107",
    ]);
    onMounted(() => {
      getAudioFeatures();
    });
    const getAudioFeatures = async () => {
      try {
        loading.value = true;
        const { data } = await getPlaylistAudioDnaByCuratorPlaylistID(
          route.params.curatorPlaylistID
        );
        audioFeatures.value = data && data.data ? data.data.audioFeatures : [];
        categories.value = data && data.data ? data.data.positions : [];
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in fetching playlist audio Dna features",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };
    return { loading, audioFeatures, categories, colours };
  },
};
</script>
<template>
  <div class="mt-2 playlist-audio-features-section">
    <EmptyCardLoading
      :loading="loading"
      :type="'skeleton'"
      v-if="loading"
      :card-height="'400px'"
      :rows="9"
      class="mb-5 mb-xl-10"
    />
    <CardToolbar
      v-if="!loading"
      title=""
      :show-toolbar-buttons="false"
      :fontStyle="true"
      :margin="true"
      :padding="true"
      :shadow-class="true"
      :show-header="false"
    >
      <template v-slot:body>
        <div class="audio-feature-chart">
          <div
            class="card-title"
            v-for="(audio_feature, i) in audioFeatures"
            :key="i"
          >
            <div class="theme-v2-color card-label fw-bolder m-lg-4">
              {{ capitalizeFirstLetter(audio_feature.name) }}
            </div>
            <LineAndAreaChart
              v-if="categories.length > 0 && audio_feature.data.length > 0"
              :show-y-axis-title="false"
              :fraction="2"
              :chart-height="250"
              :categories="categories"
              :chart-series="[
                {
                  name: audio_feature.name,
                  data: audio_feature.data,
                  color: colours[i],
                },
              ]"
              :chart-type="'area'"
              :chart-stroke="{
                curve: 'straight',
                show: true,
                width: [3, 1],
                dashArray: [0, 8],
              }"
              :x-axis-date="false"
            />
          </div>
        </div>
      </template>
    </CardToolbar>
  </div>
</template>
