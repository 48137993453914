<script>
import SubTopNavBar from "@/common/components/SubTopNavBar.vue";
import KpiCardV2 from "@/common/components/KpiCardV2.vue";
import PlaylistTracks from "@/playlists/components/PlaylistTracks.vue";
import PlaylistAudioFeatures from "@/playlists/components/PlaylistAudioFeatures.vue";
import EmptyCardLoading from "@/common/components/EmptyCardLoading.vue";
import { onMounted, ref } from "vue";
import {
  getPlaylistDetailByCuratorPlaylistID,
  getPlaylistFollowerByCuratorPlaylistID,
  savePlaylistDetailByCuratorPlaylistID,
} from "@/api/playlists.api";
import { useRoute } from "vue-router";
import { checkIsValueAvailableOrNot, getTagName } from "@/utils/staticHelper";
import CardToolbar from "@/common/components/CardToolbar.vue";
import LineAndAreaChart from "@/common/components/LineAndAreaChart.vue";
import { ElNotification } from "element-plus";
export default {
  name: "PlaylistDetail",
  methods: { getTagName, checkIsValueAvailableOrNot },
  components: {
    LineAndAreaChart,
    CardToolbar,
    SubTopNavBar,
    KpiCardV2,
    PlaylistTracks,
    PlaylistAudioFeatures,
    EmptyCardLoading,
  },
  setup() {
    const loading = ref(false);
    const updateLoading = ref(false);
    const playlistDetail = ref({});
    const followersSeries = ref([]);
    const followersCategories = ref([]);
    const followersLoading = ref(false);
    const route = useRoute();
    const tabMenuList = ref([
      {
        id: "playlist_statistics",
        name: "Statistics",
        icon: "/media/svg/playlist.svg",
        alt: "statistics",
      },
      {
        id: "playlist_followers",
        name: "Followers",
        icon: "/media/svg/playlist.svg",
        alt: "followers",
      },
      {
        id: "playlist_track_table",
        name: "Tracks",
        icon: "/media/svg/playlist.svg",
        alt: "playlistTracks",
      },
      {
        id: "playlist_audio_features",
        name: "Playlist Audio Features DNA",
        icon: "/media/svg/playlist.svg",
        alt: "audio_features",
      },
    ]);
    onMounted(() => {
      document.title = "Playlist";
      getPlaylistDetail();
      getPlaylistFollowers();
    });

    const getPlaylistDetail = async () => {
      try {
        loading.value = true;
        const { data } = await getPlaylistDetailByCuratorPlaylistID(
          route.params.curatorPlaylistID
        );
        playlistDetail.value = data && data.data ? data.data : {};
        document.title =
          playlistDetail.value && playlistDetail.value.name
            ? "Playlist | " + playlistDetail.value.name
            : "Playlist";
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in fetching playlist details",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };
    const getPlaylistFollowers = async () => {
      try {
        followersLoading.value = true;
        const { data } = await getPlaylistFollowerByCuratorPlaylistID(
          route.params.curatorPlaylistID
        );
        const yAxis = [];
        followersSeries.value = [];
        followersCategories.value = [];
        for (const value of data.data) {
          if (value.value && value.timestp) {
            yAxis.push(value.value);
            followersCategories.value.push(value.timestp);
          }
        }
        followersSeries.value.push({
          name: "Followers",
          data: yAxis,
        });
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in fetching playlist followers",
          type: "error",
        });
      } finally {
        followersLoading.value = false;
      }
    };

    const updateDetail = async () => {
      try {
        updateLoading.value = true;
        await savePlaylistDetailByCuratorPlaylistID(
          route.params.curatorPlaylistID,
          true
        );
        ElNotification({
          title: "Success",
          message: "Request sent for updating playlist details",
          type: "success",
        });
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in updating playlist details",
          type: "error",
        });
      } finally {
        updateLoading.value = false;
      }
    };

    return {
      loading,
      followersLoading,
      updateLoading,
      tabMenuList,
      playlistDetail,
      followersSeries,
      followersCategories,
      updateDetail,
    };
  },
};
</script>
<template>
  <div
    id="playlist_detail"
    class="d-flex justify-content-start"
    v-loading.fullscreen.lock="loading"
  >
    <div class="playlist-main-content w-100">
      <div class="d-flex page-heading">
        Playlist :
        {{ playlistDetail && playlistDetail.name ? playlistDetail.name : "" }}
      </div>
      <sub-top-nav-bar
        :tabs-list="tabMenuList"
        current-active-tab="#playlist_statistics"
        main-id="playlist_menu_list"
        :sub-list="[]"
        :scroll-to-element-margin-main="80"
        sub-id="playlist_sub_id"
        :have-sub-list="false"
        :update-button="true"
        @updateDetail="updateDetail"
        :loading="updateLoading"
      />
      <div class="row mt-8">
        <div id="playlist_statistics">
          <div class="artist-content-heading">Statistics</div>
          <div class="mt-2 mb-3 mb-xl-5">
            <el-row :gutter="12">
              <el-col
                class="margin-bottom-12"
                :lg="6"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="
                    checkIsValueAvailableOrNot(
                      'object',
                      playlistDetail,
                      'ownerName'
                    )
                  "
                  :text="'Curator Name'"
                /> </el-col
              ><el-col
                class="margin-bottom-12"
                :lg="6"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="
                    checkIsValueAvailableOrNot('object', playlistDetail, 'name')
                  "
                  :text="'Playlist Name'"
                /> </el-col
              ><el-col
                class="margin-bottom-12"
                :lg="6"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="
                    checkIsValueAvailableOrNot(
                      'object',
                      playlistDetail,
                      'followers'
                    )
                  "
                  :text="'Playlist Followers'"
                />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="6"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="
                    checkIsValueAvailableOrNot(
                      'object',
                      playlistDetail,
                      'numTrack'
                    )
                  "
                  :text="'Playlist Tracks'"
                />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="6"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="
                    checkIsValueAvailableOrNot(
                      'object',
                      playlistDetail,
                      'catalog'
                    )
                  "
                  :text="'Catalogue Type'"
                /> </el-col
              ><el-col
                class="margin-bottom-12"
                :lg="6"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="
                    getTagName(
                      playlistDetail && playlistDetail.tags
                        ? playlistDetail.tags
                        : [],
                      'name'
                    )
                  "
                  :text="'Genres'"
                /> </el-col
              ><el-col
                class="margin-bottom-12"
                :lg="6"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="
                    checkIsValueAvailableOrNot(
                      'object',
                      playlistDetail,
                      'fdiffMonth'
                    )
                  "
                  :text="'Playlist Followers Diff of Last Month'"
                />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="6"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="
                    checkIsValueAvailableOrNot(
                      'object',
                      playlistDetail,
                      'code2'
                    )
                  "
                  :text="'Playlist Country'"
                />
              </el-col>
            </el-row>
          </div>
        </div>
        <div id="playlist_followers">
          <div class="artist-content-heading">Followers</div>
          <div class="mt-2 playlist-followers-section">
            <EmptyCardLoading
              :loading="followersLoading"
              :type="'skeleton'"
              v-if="followersLoading"
              :card-height="'400px'"
              :rows="9"
              class="mb-5 mb-xl-10"
            />
            <CardToolbar
              v-if="
                !followersLoading &&
                followersCategories.length > 0 &&
                followersSeries.length > 0
              "
              title=""
              :show-toolbar-buttons="false"
              :fontStyle="true"
              :margin="true"
              :padding="true"
              :shadow-class="true"
              :show-header="false"
            >
              <template v-slot:body>
                <LineAndAreaChart
                  :chart-height="400"
                  :categories="followersCategories"
                  :chart-series="followersSeries"
                  :chart-type="'area'"
                  :chart-stroke="{
                    curve: 'straight',
                    show: true,
                    width: [3, 1],
                    dashArray: [0, 8],
                  }"
                  :tooltip-x-format="'MMM DD, YYYY'"
                />
              </template>
            </CardToolbar>
          </div>
        </div>
        <div id="playlist_track_table">
          <div class="artist-content-heading">Tracks</div>
          <PlaylistTracks
            :curatorPlaylistID="$route.params.curatorPlaylistID"
          />
        </div>
        <div id="playlist_audio_features">
          <div class="artist-content-heading">Audio Features DNA</div>
          <PlaylistAudioFeatures />
        </div>
      </div>
    </div>
  </div>
</template>
